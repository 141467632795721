import { HTTP_403 } from "../constants/http"
import unfetch from 'isomorphic-unfetch'
import { LOGIN_PATHNAME, LOGOUT_PATHNAME } from "../constants/route"

const clientfetch = (...args) => unfetch(...args)
  .then(res => {
    // Forbidden by server, we'll redirect 403 user to login page
    const path = `${window.location.pathname}${window.location.search}`
    if (res.status === HTTP_403.status) { window.location.href = `${LOGOUT_PATHNAME}?loginfrom=${path}&from=${LOGIN_PATHNAME}` }
    return res.json()
  })
  .catch(error => {
    return { error }
  })

export default clientfetch

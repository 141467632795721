import { getTranslation } from '../helpers/i18n'
import styled from 'styled-components'
import Landing from '../components/layout/Landing'
import { useState, useEffect } from 'react'
import { MEDIA_QUERY } from '../constants/style'
import { user } from '../helpers/clientStorage'
import queryString from 'query-string'
import { useRouter } from 'next/router'
import LoginSignupForm from '../components/user/FavoriteTable/LoginSignupForm'

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;

  @media ${MEDIA_QUERY.mobileL} {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
`

export default function Login({ i18n }) {
  const [ error, setError ] = useState('')
  const router = useRouter()
  useEffect(() => {
    const { email } = user.get()
    if (email) {
      const { from } = queryString.parse(window.location.search) || {}
      router.replace(from ? from : '/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const loginProps = {
    error,
    setError,
  }

  return (
    <Landing i18n={i18n}>
      <LayoutWrapper>
        <LoginSignupForm i18n={i18n} loginProps={loginProps} />
      </LayoutWrapper>
    </Landing>
  )
}

export async function getStaticProps() {
  return getTranslation()
}

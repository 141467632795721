const HTTP_400 = {
  'error': 'Bad request',
  'status': 400,
};

const HTTP_401 = {
  'error': 'Unauthorized',
  'status': 401,
};

const HTTP_403 = {
  'error': 'Forbidden',
  'status': 403,
};

const HTTP_404 = {
  'error': 'Not found',
  'status': 404,
};

const HTTP_498 = {
  'error': 'uuid error',
  'status': 498,
};

const HTTP_500 = {
  'error': 'Internal server error',
  'status': 500,
};

export {
  HTTP_400,
  HTTP_401,
  HTTP_403,
  HTTP_404,
  HTTP_498,
  HTTP_500
}
